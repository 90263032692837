import { PropsWithChildren } from 'react'

import { NeighbourhoodSummaryEntry } from '@/data/getNeighbourhoods'
import { NeighbourhoodCarousel } from './NeighbourhoodCarousel/NeighbourhoodCarousel'
import { LifestyleHeadingButton } from './SearchHero/LifestyleHeadingButton'
import { ShowcaseStage } from './Showcase/ShowcaseStage'

interface NeighbourhoodBrowserProps {
  className?: string
  neighbourhoods: NeighbourhoodSummaryEntry[]
  standalone?: boolean
  dim?: boolean
}

export function NeighbourhoodBrowser({ className, neighbourhoods }: PropsWithChildren<NeighbourhoodBrowserProps>) {
  return (
    <ShowcaseStage className={className}>
      <NeighbourhoodCarousel neighbourhoods={neighbourhoods} />
      <LifestyleHeadingButton />
    </ShowcaseStage>
  )
}
