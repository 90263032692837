import { createMachine } from 'xstate'

export const welcomeOverlayMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QHUwBsDGB7AtmA8gG5gBOaAhgJ4B0AMllFgMQAesALue2NeQGbcSACjQMsAEQCuJLgEssAOwCUTVJlwFiZKnTEBtAAwBdRKAAOWWLPbyFpkC0QBWAEwAWagHYAzG-cGADgMATm9gl2CAGhBKRG8nAOpg5OT-YICnBKcAX2zotWw8IlIKGgAhSQAvSoB3LBIIWFYOLh5+QSEAIyra+sapGRtFFQKNYu1ynrqG2EMTJBALKyG7BccEAEYDADYPT23Mjc9AgO2DA09o2IR4xJTUtxCMrNz89ELNEp1xRTBmzm4vAEpCEYBY1nE6CoI3eYy0pWoPwUYDm9iW1ls9nWGycbmCXgM3gMLl83m8EW2VziCSS9zSzwyrxAoyK8J0AEkFBjyGgAAQQKGUJiohbolZYxCeTwbahnHE4x4BFyeFyUmLOJwy4IGDbuTxuNzbI7JJksz4Taic7l8gWlf6tIEdWRcmw8yGlGHqVlfGhW102wUi8yWDGKCWbTyJVWZJz7DbebYBckBKk3Gn3cKPdKZRlMhRYAXwBZm8alNEh8VrRAAWic1DxGTc3gCGWCmRcEVT1e21HOffOibJHfjpth3ot9EY5eWmKrN18BKJJObRybbdTyrr-YMhsybheeWZY-NCIq1WmjWnodWoHW21CsvcLe1xrc+w3mWoTn77gTupVo5eie3y-Fela3og-4eGEBiatsJIBG4WxqtcRJbv2QT3gEGx+J4gEfKWHIurIPL8oKYGzhBCB+F+Or7kaOE7m+lzqmmni0ikAQqlsxr4XCPrUAAouC7AUWGc4bPePZ4qkGynDqCSpvEPaeN+OphE4baRrkuRAA */
  createMachine({
    id: 'WelcomeOverlay',
    description:
      '[Figma](https://www.figma.com/file/zyEfO9LHb9clmJC8eXMgRz/Duet---Website?node-id=1539%3A135770&t=6eU8FXtsqy1NdVcw-4)\n\n**Entry:**\n- Scroll to top\n- Lock body scroll\n\n**Exit:**\n- Unlock body scroll',
    initial: 'Initial delay',
    states: {
      Logo: {
        description:
          '**Entry:**\n- Opacity from 0 to 1\n- Scale from 0.8 to 1\n\n**Exit:**\n- Opacity from 1 to 0\n- Scale from 1 to 0.2',

        after: {
          logoDuration: {
            target: '#WelcomeOverlay.Buzzwords',
            actions: [],
            internal: false,
          },
        },
      },

      Buzzwords: {
        description:
          '**Entry:**\n- Stagger words by row\n- Opacity from 0 to 1\n- Translate from edge of screen towards centre\n\n**Exit:**\n- Stagger words by row\n- Opacity from 1 to 0\n- Translate Y -400% (move up 4x height of words)',
        after: {
          buzzwordsDuration: {
            target: 'Done',
            actions: [],
          },
        },
      },

      Done: {
        entry: 'Fire completion callback',

        description: `**Entry:**
- Backdrop fades to 0`,

        after: {
          exitDelay: 'Exit',
        },
      },

      'Initial delay': {
        after: {
          initialDelay: {
            target: '#WelcomeOverlay.Logo',
            actions: [],
            internal: false,
          },
        },

        always: {
          target: 'Exit',
          cond: 'Should skip animation',
        },
      },

      Exit: {
        type: 'final',
      },
    },
    schema: {
      context: {} as { skipAnimation: boolean },
    },
    context: { skipAnimation: false },
    predictableActionArguments: true,
    preserveActionOrder: true,
    tsTypes: {} as import('./WelcomeOverlay.machine.typegen').Typegen0,
  })
