export const Old = (
  <svg height="32" viewBox="0 0 151 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.45 43.61C9.49 43.61.23 34.59.23 21.97.23 9.35 9.49.33 21.45.33c11.96 0 21.22 9.02 21.22 21.64 0 12.62-9.26 21.64-21.22 21.64Zm0-42.03c-7.88 0-11.24 7.82-11.24 20.38 0 12.56 3.37 20.38 11.24 20.38 7.88 0 11.24-7.82 11.24-20.38 0-12.56-3.37-20.38-11.24-20.38ZM74.24.92v.12c-2.77 1.56-3.37 3.37-3.37 7.33v33.07h8c7.51 0 10.58-4.03 14.49-11.3h.12l-2.1 12.87H58.49v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.39c0-3.97-.6-5.77-3.37-7.33V.94h15.75V.92ZM108.33 42.89c2.77-1.56 3.37-3.37 3.37-7.33V8.39c0-3.97-.6-5.77-3.37-7.33V.94h16.71c14.73 0 25.19 6.85 25.19 20.74 0 14.55-10.7 21.34-24.35 21.34h-17.56v-.13h.01Zm16.71-1.45c9.2 0 15.45-6.31 15.45-19.78 0-12.26-6.19-19.18-15.99-19.18h-3.79v38.96h4.33Z"
      fill="currentColor"
    />
  </svg>
)

export const Sale = (
  <svg height="32" viewBox="0 0 188 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71 9.02c0 5.11 4.39 6.85 9.44 8.36 6.73 1.98 14.49 4.03 14.49 13.71 0 8.42-7.33 12.68-15.45 12.68-4.87 0-8.24-1.92-10.7-1.92-1.68 0-2.83.84-4.09 1.92L.5 30.12h.12c3.73 7.63 9.56 12.38 15.51 12.38 5.11 0 8.72-2.83 8.72-7.88 0-5.89-5.77-7.33-11.66-9.2C7.12 23.56.93 21.15.93 12.8.93 5.28 7.12.48 15.48.48c4.63 0 7.76 1.86 9.98 1.86 1.68 0 2.65-1.02 3.55-1.86l.24 12.14h-.12C25.82 6.37 21.8 1.74 15.48 1.74c-4.23 0-7.77 2.53-7.77 7.28ZM54.18 43.17h-10.1v-.12c2.28-1.32 3.91-3.73 5.65-7.52L65.72.48h.24l17.01 35.95c1.8 3.79 2.71 5.29 4.87 6.61v.12H71.37v-.12c1.74-.9 2.65-1.86 2.65-3.31 0-1.08-.36-2.17-.96-3.49l-2.83-6.13H54.12l-2.16 4.99c-.54 1.14-.9 2.34-.9 3.61 0 1.86 1.14 3.43 3.13 4.33v.13h-.01Zm.72-14.73h14.55L62 12.2l-7.1 16.24ZM117.61 1.08v.12c-2.77 1.56-3.37 3.37-3.37 7.33V41.6h8c7.51 0 10.58-4.03 14.49-11.3h.12l-2.1 12.87h-32.89v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.55c0-3.97-.6-5.77-3.37-7.33V1.1h15.75v-.02ZM180.32 1.08c1.68 0 2.95-.12 3.73-.48l2.04 11.18h-.12c-3.37-5.89-6.55-9.14-13.77-9.14h-8.12v18.1h6.67c4.87 0 6.61-1.2 8.6-6.79h.12v14.73h-.12c-1.98-5.53-3.73-6.25-8.6-6.25h-6.67v19.18h9.14c7.52 0 11-4.09 14.37-10.22h.12l-1.92 11.78H151.7v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.55c0-3.97-.6-5.77-3.37-7.33V1.1h28.62v-.02Z"
      fill="currentColor"
    />
  </svg>
)

export const Theirs = (
  <svg height="32" viewBox="0 0 284 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.39 1.24c1.68 0 2.95-.12 3.73-.48l2.04 13.47h-.12C38.41 6.59 36.13 2.81 29.57 2.81h-3.49v33.37c0 3.97.78 5.47 3.55 7.03v.12h-16.1v-.12c2.77-1.56 3.49-3.07 3.49-7.03V2.8h-3.49C6.98 2.8 4.75 6.59.12 14.22H0L2.04.75c.78.36 2.04.48 3.67.48h31.68v.01ZM60.18 8.69c0-3.97-.6-5.77-3.37-7.33v-.12h15.75v.12c-2.77 1.56-3.37 3.37-3.37 7.33v12.14h20.14V8.69c0-3.97-.6-5.77-3.37-7.33v-.12h15.75v.12c-2.77 1.56-3.37 3.37-3.37 7.33v27.17c0 3.97.6 5.77 3.37 7.33v.12H85.97v-.12c2.77-1.56 3.37-3.37 3.37-7.33V22.57H69.2v13.29c0 3.97.6 5.77 3.37 7.33v.12H56.81v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.69ZM145.13 1.24c1.68 0 2.95-.12 3.73-.48l2.04 11.18h-.12c-3.37-5.89-6.55-9.14-13.77-9.14h-8.12v18.1h6.67c4.87 0 6.61-1.2 8.6-6.79h.12v14.73h-.12c-1.98-5.53-3.73-6.25-8.6-6.25h-6.67v19.18h9.14c7.52 0 11-4.09 14.37-10.22h.12l-1.92 11.78h-34.09v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.71c0-3.97-.6-5.77-3.37-7.33v-.12h28.62v-.02ZM182.41 43.2v.12h-15.75v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.7c0-3.97-.6-5.77-3.37-7.33v-.12h15.75v.12c-2.77 1.56-3.37 3.37-3.37 7.33v27.17c0 3.97.6 5.77 3.37 7.33ZM218.84 1.24c8.9 0 15.75 3.07 15.75 11.18 0 7.33-6.85 10.64-13.83 10.76 6.31.42 9.5 3.07 11.12 7.82l1.56 4.81c1.44 4.33 2.95 5.83 6.49 7.39v.12h-12.69c-1.32-1.38-2.28-3.73-3.19-6.49l-1.98-6.61c-1.2-3.67-2.83-6.49-7.58-6.49h-4.93v12.14c0 3.97.6 5.77 3.37 7.33v.12H197.2v-.12c2.77-1.56 3.37-3.37 3.37-7.33V8.7c0-3.97-.6-5.77-3.37-7.33v-.12h21.64v-.01Zm-9.26 20.86h5.95c5.83 0 9.44-3.01 9.44-9.5 0-6.19-3.31-9.8-9.44-9.8h-5.95v19.3ZM259.18 9.17c0 5.11 4.39 6.85 9.44 8.36 6.73 1.98 14.49 4.03 14.49 13.71 0 8.42-7.33 12.69-15.45 12.69-4.87 0-8.24-1.92-10.7-1.92-1.68 0-2.83.84-4.09 1.92l-.9-13.65h.12c3.73 7.63 9.56 12.38 15.51 12.38 5.11 0 8.72-2.83 8.72-7.88 0-5.89-5.77-7.33-11.66-9.2-6.07-1.86-12.26-4.27-12.26-12.62 0-7.52 6.19-12.33 14.55-12.33 4.63 0 7.76 1.86 9.98 1.86 1.68 0 2.65-1.02 3.55-1.86l.24 12.14h-.12c-3.31-6.25-7.33-10.88-13.65-10.88-4.22.01-7.77 2.54-7.77 7.28Z"
      fill="currentColor"
    />
  </svg>
)

export const New = (
  <svg aria-label="New" height="32" viewBox="0 0 168 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.81.92h8.48l21.7 31.2h.12V.92h7.75V43h-8.48L8.68 11.74h-.12V43H.8V.92h.01ZM59.97.92h31.26v6.85H67.72v10.46h21.4v6.55h-21.4v11.3h23.45v6.91h-31.2V.92ZM106.8.92h8l7.39 31.2h.12L133.55.92h7.15l11.24 31.2h.12l7.46-31.2h7.94L157.24 43h-8.42l-11.66-30.96h-.12L125.32 43h-8.3L106.8.92Z"
      fill="currentColor"
    />
  </svg>
)

export const Sold = (
  <svg aria-label="Sold" height="32" viewBox="0 0 203 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m.8 28.98 7.64-1.44c1.2 6.73 5.17 9.86 12.5 9.86 5.83 0 9.32-2.1 9.32-5.65 0-3.31-2.65-5.11-12.2-7.21C6.28 22.01 1.83 18.53 1.83 12.28 1.83 5.49 8.26.26 18 .26c9.44 0 16.65 4.99 18.34 12.75l-7.51 1.74c-1.26-5.05-5.11-7.82-10.88-7.82-4.93 0-8.36 2.16-8.36 5.23 0 2.58 2.29 3.97 11.6 5.95 11.78 2.52 16.83 6.43 16.83 13.59 0 7.58-6.67 12.38-17.13 12.38C9.52 44.07 2.25 38.6.8 28.98ZM53.65 22.12C53.65 9.37 62.73.18 75.59.18c12.93 0 21.94 9.2 21.94 21.94 0 12.75-9.02 21.94-21.94 21.94-12.86.01-21.94-9.19-21.94-21.94Zm36.07 0c0-9.02-5.71-15.09-14.13-15.09-8.36 0-14.13 6.07-14.13 15.09s5.77 15.03 14.13 15.03c8.42 0 14.13-6.01 14.13-15.03ZM116.17 1.08h7.76v35.17h22.42v6.91h-30.18V1.08ZM164.63 1.08h16.83c12.93 0 21.16 7.15 21.16 21.04 0 14.43-8.3 21.04-21.28 21.04h-16.71V1.08Zm16.6 35.17c8.84 0 13.59-3.97 13.59-14.13 0-9.74-4.69-14.19-13.53-14.19h-8.9v28.32h8.84Z"
      fill="currentColor"
    />
  </svg>
)

export const Yours = (
  <svg aria-label="Yours" height="32" viewBox="0 0 264 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.67 26.49.87 1.24h9.02L22.46 20h.12L35.2 1.24h9.02l-17.8 25.25v16.83h-7.75V26.49ZM54.33 22.28C54.33 9.53 63.41.34 76.27.34c12.93 0 21.94 9.2 21.94 21.94 0 12.74-9.02 21.94-21.94 21.94-12.87 0-21.94-9.19-21.94-21.94Zm36.07 0c0-9.02-5.71-15.09-14.13-15.09-8.36 0-14.13 6.07-14.13 15.09s5.77 15.03 14.13 15.03c8.42 0 14.13-6.01 14.13-15.03ZM116.55 27.21V1.24h7.76v25.67c0 7.39 3.67 10.4 10.94 10.4 7.21 0 10.88-3.01 10.88-10.4V1.24h7.76v25.97c0 11.42-6.79 17.01-18.64 17.01-11.91 0-18.7-5.59-18.7-17.01ZM199.64 27.21l10.52 16.11h-8.84l-10.16-15.93h-8.78v15.93h-7.76V1.24h21.58c9.14 0 14.73 5.05 14.73 13.05 0 7.03-4.21 11.72-11.3 12.8v.12h.01Zm-4.09-6.73c4.93 0 7.63-2.16 7.63-6.25s-2.46-6.13-7.75-6.13h-13.05v12.38h13.17ZM226.21 29.13l7.63-1.44c1.2 6.73 5.17 9.86 12.51 9.86 5.83 0 9.32-2.1 9.32-5.65 0-3.31-2.65-5.11-12.21-7.21-11.78-2.53-16.23-6.01-16.23-12.26 0-6.79 6.43-12.02 16.17-12.02 9.44 0 16.65 4.99 18.34 12.75l-7.52 1.74c-1.26-5.05-5.11-7.82-10.88-7.82-4.93 0-8.36 2.16-8.36 5.23 0 2.58 2.29 3.97 11.6 5.95 11.78 2.52 16.83 6.43 16.83 13.59 0 7.57-6.67 12.38-17.13 12.38-11.35-.01-18.62-5.48-20.07-15.1Z"
      fill="currentColor"
    />
  </svg>
)
