import { motion, Variants } from 'framer-motion'

interface BackdropProps {
  variants: Variants
}

export function Backdrop({ variants }: BackdropProps) {
  return (
    <motion.div
      key="backdrop"
      className="absolute inset-0 bg-neutral-850"
      variants={variants}
      initial="initial"
      animate="enter"
      exit="leave"
    />
  )
}
