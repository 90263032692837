'use client'

import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { GlobalAppContext } from '../GlobalAppContext'
import { ShowcaseContext } from '../Showcase/ShowcaseContext'

interface LifestyleHeadingProps {
  className?: string
}

export function LifestyleHeadingButton({ className }: LifestyleHeadingProps) {
  const [, sendToShowcase] = ShowcaseContext.useActor()
  const [, sendToGlobalApp] = GlobalAppContext.useActor()
  // Heading button only stays up when not active or activating.
  const active = ShowcaseContext.useSelector((state) => state.matches('Active') || state.matches('Deactivating'))

  const handleClick = () => {
    sendToGlobalApp({ type: 'Hide header bar' })
    sendToShowcase({ type: 'Activate showcase' })
  }

  useEffect(() => {
    if (!active) {
      sendToGlobalApp({ type: 'Show header bar' })
    }
  }, [active, sendToGlobalApp])

  return (
    <div
      // overflow hidden to hide weird pixel that appears on ios devices.
      className={twMerge(
        'absolute inset-x-0 -bottom-px -top-1 flex items-center justify-center overflow-hidden bg-neutral-850/50 duration-500 ease-out',
        active ? 'pointer-events-none opacity-0' : 'pointer-events-auto opacity-100',
        className,
      )}
    >
      <button
        aria-label="Find your lifestyle"
        className="flex flex-col items-center justify-center border-b border-b-current bg-transparent px-0 text-center outline-none drop-shadow-lg transition focus:outline-none md:items-start md:gap-y-2 lg:gap-y-4"
        onClick={handleClick}
      >
        <span className="font-sans text-sm font-semibold uppercase tracking-widest xs:text-xl md:text-sm lg:text-xl">
          Or find your
        </span>
        <span className="relative top-0.5 mb-1.5 block h-12 text-center font-display text-5xl font-semibold uppercase leading-none tracking-widest xs:h-20 xs:text-7xl md:mb-0 md:h-16 md:text-5xl lg:-top-1 lg:h-20 lg:text-7xl">
          Lifestyle
        </span>
      </button>
    </div>
  )
}
