'use client'

import { motion } from 'framer-motion'

import { NeighbourhoodSummaryEntry } from '@/data/getNeighbourhoods'
import { cn } from '@/lib/utils'
import { NeighbourhoodBrowser } from '../NeighbourhoodBrowser'
import { ShowcaseContext } from '../Showcase/ShowcaseContext'

type BrowseViewProps = {
  neighbourhoods: NeighbourhoodSummaryEntry[]
}

export function BrowseView({ neighbourhoods }: BrowseViewProps) {
  const active = ShowcaseContext.useSelector(
    // Deem active when active, activating or deactivating (as in be full screen). This allows for other animations to sort themselves out.
    (state) => state.matches('Active') || state.matches('Activating') || state.matches('Deactivating'),
  )

  return (
    <div
      className={cn(
        'relative flex w-full flex-1 items-stretch justify-end bg-neutral-850 text-neutral-50 transition-all perspective-500 safari:perspective-none',
      )}
    >
      <motion.div
        layout
        className={cn(
          'flex w-full flex-1 origin-bottom items-stretch justify-end',
          active && 'absolute bottom-0 h-screen',
        )}
      >
        <NeighbourhoodBrowser neighbourhoods={neighbourhoods} />
      </motion.div>
    </div>
  )
}
