'use client'

import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { BackLink } from '../BackLink'
import { GlobalAppContext } from '../GlobalAppContext'
import { SearchHeroContext } from './SearchHeroContext'
import { SearchHeroSuburbSelector } from './SearchHeroSuburbSelector'

interface SearchViewProps {
  className?: string
}

export function SearchView({ className }: SearchViewProps) {
  const [, sendToGlobalApp] = GlobalAppContext.useActor()
  const [, sendToSearchHero] = SearchHeroContext.useActor()
  const searching = SearchHeroContext.useSelector((state) => state.matches('Search suburbs'))
  const handleClickBack = () => sendToSearchHero({ type: 'Return to initial state' })

  const layout = {
    searching: { justifyContent: 'start', alignItems: 'stretch' },
    idle: { justifyContent: 'center', alignItems: 'center' },
  }

  useEffect(() => {
    sendToGlobalApp({ type: searching ? 'Hide header bar' : 'Show header bar' })
    return () => {
      sendToGlobalApp({ type: 'Show header bar' })
    }
  }, [searching, sendToGlobalApp])

  return (
    <div
      className={twMerge(
        'group relative flex flex-1 flex-col text-neutral-50 transition-[backdrop-filter,background-color] duration-500 ease-linear lg:mb-0',
        searching
          ? 'focus-within z-10 -mb-[calc(50vh+32px)] min-h-screen bg-neutral-850/80 backdrop-blur'
          : 'min-h-0 bg-neutral-850/0 backdrop-blur-0',
        className,
      )}
      style={searching ? layout.searching : layout.idle}
    >
      <motion.div layout className="origin-top">
        <motion.div className="box-border flex w-full max-w-xs flex-1 flex-col items-start justify-start px-6 text-center font-semibold uppercase tracking-wider group-[.focus-within]:mt-4 group-[.focus-within]:max-w-none group-[.focus-within]:items-stretch group-[.focus-within]:px-6 group-[.focus-within]:text-left xs:max-w-md md:max-w-sm md:items-end md:px-6 md:group-[.focus-within]:mt-24 lg:max-w-lg lg:px-10 lg:group-[.focus-within]:px-10">
          <SearchHeroSuburbSelector
            label={
              <div className="relative flex" style={searching ? layout.searching : layout.idle}>
                {searching ? (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout="position" className="block">
                    <BackLink onClick={handleClickBack} className="mb-6 self-start" collapse={false} />
                  </motion.div>
                ) : (
                  <motion.label
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    layout="position"
                    className="block font-sans text-sm tracking-widest group-[.focus-within]:hidden xs:text-xl md:text-sm lg:text-xl"
                  >
                    Search by
                  </motion.label>
                )}
              </div>
            }
          />
        </motion.div>
      </motion.div>
    </div>
  )
}
