import { motion, Variants } from 'framer-motion'

interface StackedLogoProps {
  variants: Variants
}

export function StackedLogo({ variants }: StackedLogoProps) {
  return (
    <motion.div
      data-cy="welcome-logo"
      key="logo"
      className="pointer-events-none absolute"
      variants={variants}
      initial="initial"
      animate="enter"
      exit="leave"
    >
      <svg
        width="238"
        height="243"
        viewBox="0 0 238 243"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative"
      >
        <path
          d="M13.35 137.312h77.32v15.831H30.94v27.899h54.55v15.071H31.12v30.3h59.47v15.899H13.35v-105zM0 104.7c6.9-3.9 8.4-8.4 8.4-18.3V18.6C8.4 8.7 6.9 4.2 0 .3V0h41.7c36.75 0 62.85 17.1 62.85 51.75 0 36.3-26.7 53.25-60.75 53.25H0v-.3zm41.7-3.6c22.95 0 38.55-15.75 38.55-49.35 0-30.6-15.45-47.85-39.9-47.85H30.9v97.2h10.8zM226.339 64.5c0 27.6-16.65 42-40.95 42-27 0-44.55-14.25-44.55-42.3V18.6c0-9.9-1.5-14.4-8.4-18.3V0h39.45v.3c-6.9 3.9-8.4 8.4-8.4 18.3v44.85c0 22.05 8.85 34.95 27.75 34.95 17.55 0 30.6-12.15 30.6-34.5V22.8c0-11.85-3.75-18.9-12-22.5V0h27.75v.3c-7.65 3.9-11.25 10.65-11.25 21.9v42.3zM176.06 153.213h-38.4v-15.901h94.72v15.7h-38.55v89.3h-17.77v-89.099z"
          fill="currentColor"
        />
      </svg>
    </motion.div>
  )
}
