'use client'

import { ChangeEvent, FocusEvent, KeyboardEvent, ReactNode } from 'react'
import { shallowEqual } from '@xstate/react'
import { LayoutGroup, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { suburbsToFilterString } from '@/app/buy/[[...suburbs]]/utils'
import { Suburbs } from '@/data/getSuburbs'
import { ActionLink } from '../ActionLink'
import { SelectedSuburbs } from '../SelectedSuburbs/SelectedSuburbs'
import { SuburbSearchInput } from '../SuburbSelector/SuburbSearchInput'
import { SuburbSelectorContext, useFilteredSuburbs } from '../SuburbSelector/SuburbSelectorContext'
import { SearchHeroContext } from './SearchHeroContext'

interface SearchHeroSuburbSelectorProps {
  label?: ReactNode
}

export function SearchHeroSuburbSelector({ label }: SearchHeroSuburbSelectorProps) {
  const [, sendToSearchHero] = SearchHeroContext.useActor()
  const searching = SearchHeroContext.useSelector((state) => state.matches('Search suburbs'))

  const [, sendToSuburbSelector] = SuburbSelectorContext.useActor()
  const { query, selectedSuburbs, numberOfListings, showSuburbList } = SuburbSelectorContext.useSelector((state) => {
    const { query, selectedSuburbs } = state.context
    const numberOfListings = selectedSuburbs.reduce((acc, suburb) => acc + suburb.listings, 0)
    const showSuburbList = state.matches('Suburb input.Focused.Suburb list visible')
    return { query, selectedSuburbs, numberOfListings, showSuburbList }
  }, shallowEqual)

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    sendToSearchHero({ type: 'Activate suburb search', searchInput: e.currentTarget })
    sendToSuburbSelector({ type: 'Enter input', input: e.currentTarget })
  }
  const handleBlur = () => {
    sendToSuburbSelector({ type: 'Leave input' })
  }
  const handleChangeQuery = (query: string) => sendToSuburbSelector({ type: 'Update query', query })
  const handleInput = (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    if (!('key' in e) || e.key !== 'Escape') {
      sendToSearchHero({ type: 'Activate suburb search', searchInput: e.currentTarget })
    }
  }
  const handleSelection = (suburbs: Suburbs) => sendToSuburbSelector({ type: 'Set selected suburbs', suburbs })
  const suburbSelected = selectedSuburbs.length > 0
  const filteredSuburbs = useFilteredSuburbs()
  return (
    <>
      <motion.div
        layout="position"
        className="flex flex-col items-center gap-y-2 group-[.focus-within]:items-stretch md:items-end lg:gap-y-4"
      >
        {label}
        <SuburbSearchInput
          query={query}
          selectedSuburbs={selectedSuburbs}
          suburbs={filteredSuburbs}
          showList={showSuburbList}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleInput}
          onInput={handleInput}
          onChangeQuery={handleChangeQuery}
          onSelection={handleSelection}
          inputClassName={twMerge(
            searching
              ? 'mt-10 placeholder:text-neutral-50/30'
              : 'placeholder:text-neutral-50 placeholder-shown:h-12 placeholder-shown:text-center placeholder-shown:text-5xl focus:h-8 group-[.focus-within]:mt-0 group-[.focus-within]:h-10 group-[.focus-within]:pb-2 xs:h-20 xs:placeholder-shown:h-20 xs:placeholder-shown:text-7xl md:h-16 md:pb-2 md:placeholder-shown:h-16 md:placeholder-shown:text-right md:placeholder-shown:text-5xl lg:h-20 lg:placeholder-shown:h-20 lg:placeholder-shown:text-7xl',
          )}
          buttonClassName="hidden mb-4 group-[.focus-within]:-top-1 group-[.focus-within]:flex group-[.focus-within]:mb-1.5 group-[.focus-within]:justify-center md:flex"
          iconClassName="group-[.focus-within]:h-4 group-[.focus-within]:w-4"
          placeholder={searching ? 'Search by suburb' : 'Suburb'}
          displayValue={(selection) => selection?.suburb ?? ''}
        />
      </motion.div>

      {searching && (
        <LayoutGroup>
          <SelectedSuburbs className="my-6 flex-wrap px-1.5 py-4" />
          <motion.div layout="position" className="flex">
            <ActionLink
              className="ml-1.5 self-start transition disabled:pointer-events-none disabled:opacity-30"
              underline
              disabled={!suburbSelected}
              href={`/buy/${suburbsToFilterString(selectedSuburbs)}`}
            >
              {suburbSelected ? `Show ${numberOfListings} listing${numberOfListings === 1 ? '' : 's'}` : 'Search'}
            </ActionLink>
          </motion.div>
        </LayoutGroup>
      )}
    </>
  )
}
