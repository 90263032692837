'use client'

import { useMemo } from 'react'
import { useMachine } from '@xstate/react'
import { AnimatePresence, useReducedMotion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { GlobalAppContext } from '../GlobalAppContext'
import { PreventScroll } from '../PreventScroll'
import { Backdrop } from './Backdrop'
import { buildEffects } from './effects'
import { StackedLogo } from './StackedLogo'
import { welcomeOverlayMachine } from './WelcomeOverlay.machine'
import { Wordlist } from './Wordlist'
import { New, Old, Sale, Sold, Theirs, Yours } from './Words'

interface WelcomeOverlayProps {
  initialDelay: number
  logoDuration: number
  buzzwordsDuration: number
  exitDelay: number
  skipAnimation: boolean
}

export function WelcomeOverlay({
  initialDelay = 500,
  logoDuration,
  buzzwordsDuration,
  exitDelay,
  skipAnimation,
}: WelcomeOverlayProps) {
  const [, sendToGlobalApp] = GlobalAppContext.useActor()
  const { playWelcomeAnimation } = GlobalAppContext.useSelector(({ context }) => context)

  const [state] = useMachine(welcomeOverlayMachine, {
    context: {
      skipAnimation,
    },
    delays: {
      initialDelay,
      logoDuration,
      buzzwordsDuration,
      exitDelay,
    },
    actions: {
      'Fire completion callback': () => {
        sendToGlobalApp({ type: 'Animation played' })
      },
    },
    guards: {
      'Should skip animation': ({ skipAnimation }, _evt) => skipAnimation || !playWelcomeAnimation,
    },
  })

  const shouldReduceMotion = useReducedMotion()

  const variants = useMemo(
    () =>
      buildEffects({
        logoDuration: shouldReduceMotion ? 2000 : logoDuration,
        buzzwordsDuration: shouldReduceMotion ? 4000 : buzzwordsDuration,
        exitDelay: shouldReduceMotion ? 2000 : exitDelay,
      }),
    [shouldReduceMotion, buzzwordsDuration, exitDelay, logoDuration],
  )

  if (state.matches('Exit')) {
    return null
  }

  return (
    <div
      data-cy="welcome-overlay"
      className={twMerge(
        'fixed inset-0 z-modal hidden h-full w-full flex-col items-center justify-center overflow-hidden text-neutral-50 md:flex',
        state.matches('Done') && 'pointer-events-none',
      )}
    >
      <AnimatePresence>
        {!state.matches('Done') && <Backdrop key="backdrop" variants={variants.backdrop} />}

        {state.matches('Logo') && <StackedLogo variants={variants.logo} />}

        {state.matches('Buzzwords') && (
          <div data-cy="welcome-words" key="buzzwords" className="pointer-events-none absolute flex w-full gap-x-14">
            <Wordlist
              className="origin-right items-end font-display"
              listVariants={variants.list}
              wordVariants={variants.leftWords}
              words={{ Old, Sale, Theirs }}
            />
            <Wordlist
              className="origin-left items-start font-sans font-semibold"
              listVariants={variants.list}
              wordVariants={variants.rightWords}
              words={{ New, Sold, Yours }}
            />
          </div>
        )}
      </AnimatePresence>
      {state.matches('Done') || <PreventScroll />}
    </div>
  )
}
