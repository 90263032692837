'use client'

import { createActorContext } from '@xstate/react'
import { ActorRefFrom, assign, createMachine } from 'xstate'

import { Suburbs } from '@/data/getSuburbs'
import { ShowcaseContext, type ShowcaseMachine } from '../Showcase/ShowcaseContext'
import { SuburbSelectorProvider } from '../SuburbSelector/SuburbSelectorContext'

const searchHeroMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGUwEMBOBjAFgCTAwHsA6ASQDsBLAFyrQBsACWGtGsAYgEEs6A3dmBYBXAEYiMYlumw4A2gAYAuolAAHIrFpUiFNSAAeiALQBGRQFYSlgGwAmSwBoQAT1MAOeyVsBOX-ZmHgDslgC+YS6omLgExOTUdIwsbBw8fFSCHEwUYFRQOGJEkjhERBBMYsQA7rCESqpIIJradHoGxghmwR4kwQG+tiHObqYAzN5jACzBtnaOEVGysYSk0XKiElKwnABKYDSSFEw0RExUifTMrEINBi067U2d5oq2fYrBimNmji7uCHs9l8NkWIHWK3iACEanUcnkCkUSmUIDt9ocMMdTudLskbhw7k0Hm19M9EEDvD1LGZ5iMAYEpjYpsChqEIpEQBRynADBD8Kt7lpHqTQC8pmMxiQxsELL5pXTTN1FCQzP4gYpfL97NLBmC+XFSJQdHjUmBBa1dCKjIgQn0prYNfL-ogLN5FN9fuEOfrViQ+ZtJGJ4EShSSOuMPNZpbKnaMEHM+oFhu6U+6zHrlvzobDhLl8oVihhSuVgxpQ5bw4DfMrLL5ZrTnQgeomgqFUymxuywkA */
  createMachine(
    {
      id: 'SearchHero',
      tsTypes: {} as import('./SearchHeroContext.typegen').Typegen0,

      schema: {
        context: {} as {
          searchInput: HTMLInputElement | null
          showcaseRef: ActorRefFrom<ShowcaseMachine> | null
        },
        events: {} as
          | { type: 'Activate suburb search'; searchInput: HTMLInputElement }
          | { type: 'Activate neighbourhood browser' }
          | { type: 'Return to initial state' },
      },

      context: {
        searchInput: null,
        showcaseRef: null,
      },

      predictableActionArguments: true,
      preserveActionOrder: true,
      initial: 'Initial state',

      states: {
        'Initial state': {
          on: {
            'Activate suburb search': {
              target: 'Search suburbs',
              actions: ['Set search field', 'Dim neighbourhood browser'],
            },
            'Activate neighbourhood browser': 'Browse neighbourhoods',
          },
        },

        'Search suburbs': {
          on: {
            'Return to initial state': {
              target: 'Initial state',
              actions: 'Reveal neighbourhood browser',
            },
          },

          invoke: {
            src: 'Listen for escape key',
            id: 'escapeKeyListener',
          },

          exit: 'Blur search field',
        },
        'Browse neighbourhoods': {
          invoke: {
            src: 'Listen for escape key',
            id: 'escapeKeyListener',
          },
          on: {
            'Return to initial state': 'Initial state',
          },
        },
      },
    },
    {
      actions: {
        'Set search field': assign({ searchInput: (_ctx, { searchInput }) => searchInput }),
        'Blur search field': ({ searchInput }) => {
          searchInput?.blur()
        },
        'Dim neighbourhood browser': ({ showcaseRef }) => showcaseRef?.send({ type: 'Dim showcase', dimmed: true }),
        'Reveal neighbourhood browser': ({ showcaseRef }) => showcaseRef?.send({ type: 'Dim showcase', dimmed: false }),
      },
      services: {
        'Listen for escape key': () => {
          return (send) => {
            const handleKeyDown = (event: KeyboardEvent) => {
              if (event.key === 'Escape') {
                send('Return to initial state')
              }
            }

            document.addEventListener('keydown', handleKeyDown)

            return () => {
              document.removeEventListener('keydown', handleKeyDown)
            }
          }
        },
      },
    },
  )

export const SearchHeroContext = createActorContext(searchHeroMachine)

export function SearchHeroProvider({ children, suburbs }: { children: React.ReactNode; suburbs: Suburbs }) {
  const showcaseRef = ShowcaseContext.useActorRef()
  return (
    <SuburbSelectorProvider suburbs={suburbs}>
      <SearchHeroContext.Provider options={{ context: { searchInput: null, showcaseRef } }}>
        {children}
      </SearchHeroContext.Provider>
    </SuburbSelectorProvider>
  )
}
