import deepmerge from '@fastify/deepmerge'
import { Variants } from 'framer-motion'

// import { isFirefox, prefersReducedMotion } from '@/utils/browser'

const isFirefox = false
const prefersReducedMotion = false

const blur = { none: 'blur(0px)', small: 'blur(10px)', large: 'blur(50px)' }

function seconds(ms: number) {
  return ms / 1000
}

function translateTo(target: Variants, direction: 'left' | 'right', offset = 10, unit = 'vw') {
  const x = `${direction === 'right' ? -offset : offset}${unit}`
  return deepmerge({
    mergeArray:
      ({ clone }) =>
      (_, source) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        clone(source),
  })(target, {
    initial: {
      originX: direction,
      x: prefersReducedMotion ? 0 : x,
    },
    enter: {
      x: [x, `0${unit}`, `0${unit}`],
    },
  })
}

export function buildEffects({ logoDuration = 3500, buzzwordsDuration = 6000, exitDelay = 4000 }) {
  const reducedMotionVariants = { initial: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 } }

  const wordsVariants: Variants = {
    initial: {
      opacity: 0,
      scale: 0.9,
      filter: blur.small,
      transition: { duration: 0.2 },
    },
    enter: {
      opacity: [0, 1, 1],
      scale: [0.9, 1, 1.04],
      filter: [blur.small, blur.none, blur.none],
      transition: { duration: seconds(buzzwordsDuration), times: [0, 0.25, 1], ease: 'easeOut' },
    },
    leave: {
      opacity: [1, 0, 0],
      scale: [1.04, 1.1, 1.1],
      transition: { duration: 1.5, times: [0, 0.15, 1] },
    },
  }

  return {
    backdrop: {
      initial: { opacity: 1 },
      enter: { opacity: 1 },
      leave: {
        opacity: 0,
        transition: {
          delay: 0.3,
          duration: seconds(exitDelay / (isFirefox ? 2 : 1)),
          ease: 'easeOut',
        },
      },
    },
    logo: prefersReducedMotion
      ? reducedMotionVariants
      : {
          initial: {
            opacity: 0,
            scale: 0.75,
            filter: blur.small,
          },
          enter: {
            opacity: 1,
            scale: 1,
            filter: blur.none,
            transition: { duration: seconds(logoDuration * 1.1), ease: 'backOut' },
          },
          leave: {
            opacity: [1, isFirefox ? 0.95 : 0.05, 0],
            scale: [1, 1, isFirefox ? 1 : 4],
            filter: [blur.none, blur.none, blur.large],
            transition: { duration: 0.75, times: [0, 0.05, 1], ease: 'circOut' },
          },
        },
    list: {
      initial: {},
      enter: {
        scale: prefersReducedMotion || isFirefox ? 1 : 1.04,
        transition: {
          staggerChildren: 0.5,
          delayChildren: isFirefox ? 0.5 : 0,
          duration: seconds(buzzwordsDuration - 2),
          type: 'easeIn',
        },
      },
      leave: {
        transition: {
          staggerChildren: 0,
        },
      },
    },
    leftWords: prefersReducedMotion ? reducedMotionVariants : translateTo(wordsVariants, 'right'),
    rightWords: prefersReducedMotion ? reducedMotionVariants : translateTo(wordsVariants, 'left'),
  } as const
}
