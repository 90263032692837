import { motion, Variants } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

interface WordlistProps {
  className?: string
  listVariants: Variants
  wordVariants: Variants
  words: Record<string, JSX.Element>
}

export function Wordlist({ words, className, listVariants, wordVariants }: WordlistProps) {
  return (
    <motion.ol
      className={twMerge('relative flex flex-1 flex-col gap-7 text-4xl uppercase', className)}
      variants={listVariants}
      initial="initial"
      animate="enter"
      exit="leave"
    >
      {Object.entries(words).map(([word, element]) => (
        <motion.li key={word} variants={wordVariants}>
          <span aria-hidden>{element}</span>
          <span className="sr-only">{word}</span>
        </motion.li>
      ))}
    </motion.ol>
  )
}
